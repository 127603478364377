const tailwind = require('../tailwind')

module.exports = {
    pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

    siteTitle: 'inkSea - Eliot Budde\'s Portfolio', // Navigation and Site Title
    siteTitleAlt: 'inkSea', // Alternative Site title for SEO
    siteTitleShort: 'inkSea', // short_name for manifest
    siteUrl: 'https://inksea.me', // Domain of your site. No trailing slash!
    siteLanguage: 'en', // Language Tag on <html> element
    siteLogo: '/inksea-icon.png', // Used for SEO and manifest
    siteDescription: 'Eliot\'s collection of works, sketches, and more',

    // siteFBAppID: '123456789', // Facebook App ID - Optional
    userTwitter: '@_inkSea', // Twitter Username
    ogSiteName: 'eliotbudde', // Facebook Site Name
    ogLanguage: 'en_US', // Facebook Language

    // Manifest and Progress color
    themeColor: tailwind.colors.teal,
    backgroundColor: tailwind.colors.blue,
}
import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Parallax } from 'react-spring/addons.cjs'

// Components
import Layout from '../components/Layout'
import ProjectCard from '../components/ProjectCard'

// Elements
import Inner from '../elements/Inner'
import { Title, BigTitle, Subtitle } from '../elements/Titles'
// import { library, icon } from 'fortawesome'
// import { faIgloo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faBehance, faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'


// Views
import Hero from '../views/Hero'
import Projects from '../views/Projects'
import About from '../views/About'
import Contact from '../views/Contact'

import avatar from '../images/avatar.png'

const ProjectsWrapper = styled.div`
    ${tw`flex flex-wrap justify-between mt-8`};
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 1200px) {
        grid-gap: 3rem;
    }
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
    }
`

const AboutHero = styled.div`
    ${tw`flex flex-col lg:flex-row items-center mt-8`};
`

const Avatar = styled.img`
    ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`

const AboutSub = styled.span`
    ${tw`text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`

const AboutDesc = styled.p`
    ${tw`text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`

const ContactText = styled.p`
    ${tw`text-grey-light font-sans text-xl md:text-2xl lg:text-3xl`};
`

const Footer = styled.footer`
    ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
`

const Index = () => (
<>
    <Layout />
    <Parallax pages={5}>
        <Hero>
            <BigTitle>
                Hello, <br /> I'm Eliot.
            </BigTitle>
            <Subtitle>I'm a Developer and Interactive Designer</Subtitle>
        </Hero>
        <Projects>
            <Title>Projects</Title>
            <ProjectsWrapper>


                <ProjectCard
                    title="Specified Air | Grow House HVAC"
                    link="http://growhousehvac.com/"
                    bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
                >
                    Client's new reach into an up and coming market.
                </ProjectCard>
                <ProjectCard
                    title="Shakespeare in Delaware Park"
                    link="https://shakespeareindelawarepark.org/"
                    bg="linear-gradient(to right, #6a11cb 0%, #2575fc 100%)"
                >
                    One of the best things about Buffalo.
                </ProjectCard>


                <ProjectCard
                    title="JazzBuffalo"
                    link=""
                    bg="linear-gradient(to top, #09203f 0%, #537895 100%)"
                >
                    One of Buffalo's underrated communities.
                </ProjectCard>
                <ProjectCard
                    title="Hearts for the Homeless"
                    link="https://heartsforthehomeless.org/"
                    bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
                >
                    A great cause based right here in Buffalo.
                </ProjectCard>


                <ProjectCard
                    title="Elder Justice"
                    link=""
                    bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
                >
                    Redesign and build for local law firm.
                </ProjectCard>
                <ProjectCard
                    title="Visions"
                    link="https://visionsvcb.org/"
                    bg="linear-gradient(120deg, #f6d365 0%, #fda085 100%)"
                >
                    Highly accessible website with A11Y view modes.
                </ProjectCard>


                <ProjectCard
                    title="Branches of Niagara"
                    link="http://branches.marketingtechonline.com/"
                    bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
                >
                    Custom WordPress website, with custom plugins. Work in Progress.
                </ProjectCard>
                <ProjectCard
                    title="Greater Buffalo ENT"
                    link="http://gbent.marketingtechonline.com/"
                    bg="linear-gradient(to right, #74ebd5 0%, #9face6 100%)"
                >
                    New ENT doctor group in WNY. Soon to be released.
                </ProjectCard>


                <ProjectCard
                    title="Jiffy-tite Motorsports"
                    link="http://motorsports.jiffy-tite.com/"
                    bg="linear-gradient(to right bottom, #16191c, #232645, #5b235b, #9a0049, #bc1010)"
                >
                    Motorsports Products for Jiffy-tite, now Oetiker.
                </ProjectCard>
                <ProjectCard
                    title="inkSea VSCode Theme"
                    link="https://marketplace.visualstudio.com/items?itemName=inksea.inksea-theme/"
                    bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
                >
                    Custom theme for VSCode.
                </ProjectCard>


            </ProjectsWrapper>
        </Projects>

        <About>
            <Title>About</Title>
            <AboutHero>
                <Avatar src={avatar} alt="Eliot Budde" />
                <AboutSub>
                    I code, I design, I write.
                </AboutSub>
            </AboutHero>
            <AboutDesc>
                Graduate of the fine arts and a self-taught developer with a passion for code.
                It all started with Python, then gently blossomed into web development.
                I’ve worked with small agencies to large global empires, but more importantly,
                I have had the opportunity to collaborate with many devoted and passionate people.
            </AboutDesc>
        </About>

        <Contact>
            <Inner>
                <Title>Get in touch</Title>
                <ContactText>
                    Say <a href="mailto:oebudde13@gmail.com">Hi</a>
                    <br></br>
                    <br></br>
                    <a href="https://github.com/inksea"><FontAwesomeIcon icon={faGithub} /> Github</a>{' | '}
                    <a href="https://www.linkedin.com/in/eliotbudde/"><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a>{' | '}
                    <a href="https://www.behance.net/inkSea"><FontAwesomeIcon icon={faBehance} /> Behance</a>{' | '}
                    <a href="https://www.instagram.com/_inkSea_/"><FontAwesomeIcon icon={faInstagram} /> Instagram</a>
                </ContactText>
            </Inner>
            <Footer>
            <FontAwesomeIcon icon={faCopyright} /> 2018 by Eliot Budde
            </Footer>
        </Contact>
    </Parallax>
</>
)

export default Index
